<template>
  <section id="about" class="about">
    <div class="container">
      <div class="row">
        <div class="col-md-12 aboutsection">
          <div class="col-md-12 col-12 pb-2">
            <h2 style="color: #0b416c">About Me</h2>
          </div>
          <div class="section-title">
            <p style="font-weight: 500">
              God has given each of you a gift from his great variety of
              spiritual gifts. Use them well to serve one another. (1peter 4:10)
            </p>
            <p>
              Greetings and prayerful wishes to you dear Reader. I am so glad to
              meet you through this website. I take this chance to share with
              you about me to have good relationship.
            </p>
            <p>
              I am <strong>Fr. Jegathesh</strong>, belong to Heralds of Good
              News congregation. I was born in Tamil Nadu, India, on Feburary
              5th, 1985. I was educated in R.C. Fatima Boys High school, in
              India. I entered Religious order in 2003. I studied philosophical
              studies in Andhra Pradesh, in India. I did my Theological Studies
              in St. Augustine Major Seminary, Arch diocese of Songea, Tanzania.
              I received my Diaconate Ordination in Divine Mercy Shrine, Diocese
              of Ifakara, Tanzania. I was ordained to priesthood in the Diocese
              of Salem, India. My priestly Ordination motto was
              <strong>“Lord is my Shephard, I shall not want“</strong>(Psalm
              23). I served the people of Tanzania from 2012 to 2019. I served
              the people of India from 2019 to 2020. Now I am serving the people
              of Immaculate Conception at Stayton as a Parochial Vicar. My
              hobbies are reading books, playing volley ball and Basketball. My
              intension to have this website is to fulfil the Spiritual thirst
              of the People. I share with you my Sunday Reflections in four
              languages: English, Tamil, Spanish and Swahili.
            </p>
            <p>
              I pray for you, please do pray for me thus we may continue to work
              for the glorification of His name and Sanctification of our souls
              and the souls entrusted to us. Wishing you prayerful and wonderful
              days in the name of Jesus Christ I remain.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
select.selectpicker {
  padding: 5px 10px;
  font-size: 16px;
}
.about {
  padding: 30px;
  background: #f9f9f9;
  position: relative;
}
.aboutsection {
  padding: 30px;
}
.about:before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.about .container {
  position: relative;
}

.about .content h2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 26px;
}

@media (max-width: 992px) {
  .about {
    padding: 30px;
  }
}
</style>
